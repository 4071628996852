<template>
  <div>{{ value }}</div>
</template>

<script>
  import { Ls } from '@/api/base/base'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'JKDZ',
    data() {
      return {
        value: '',
      }
    },
    mounted() {
      this.fetchData()
      //let Staff_Name = sessionStorage.getItem('Staff_Name')
      //let DD_ID = sessionStorage.getItem('DD_ID')
      //Emas(Staff_Name, DD_ID, 'JKDZ', '健康地址', 'zzdcg.yy.gov.cn/green')
    },
    methods: {
      async fetchData() {
        const data = await Ls({ code: this.code })
        console.log(data)
        this.value = '{"code":' + data.code + ',"msg":"' + data.msg + '"}'
        // this.value=data.data
      },
    },
  }
</script>

<style scoped></style>
